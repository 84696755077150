import React, { Component } from 'react'
import Layout from '../../components/layout';
import { Container, Form, FormGroup, Input, Label, ButtonGroup, Button } from 'reactstrap'
import { isLoggedIn } from '../../services/auth';
import { navigate } from '@reach/router';
import moment from 'moment'
import sha256 from 'sha256'
import { Banner } from '../../components/banner'

const jQuery = require('jquery')

// TODO: Finish API Testing Page
export default class api extends Component {
    constructor (props) {
        super(props);

        if(typeof window !== "undefined"){
            window.$ = window.jQuery = require('jquery');
        }
    
        this.state = { 
            window: 'current',
            mode : 'uat',
            umid : "UMID_877772003",
            apiKey : "154eb31c-0f72-45bb-9249-84a1036fd1ca",
            secretKey : "38a4b473-0295-439d-92e1-ad26a8c60279",
            amount : "1000",

            payloadReqContent : "",
            payloadReqSign : "",
            payloadResContent : "",
            payloadResSign : "",

            status : false
        }
    
        this.onRadioBtnClick = this.onRadioBtnClick.bind(this)
    }

    componentDidMount() {
        if(!isLoggedIn()){
            window.location.replace('/signin/?referrer=' + encodeURIComponent(this.props.location.pathname))
        }
    }
    
    onRadioBtnClick(val, type) {
        if(type === 'window'){
            this.setState({ window : val })
        } else if(type === 'mode'){
            this.setState({ mode : val })
        }

    }

    handleChange = (event) => {
        const name = event.target.id
        const value = event.target.value

        this.setState({
            [name] : value
        })
    }

    submitHandler(){
        
        this.setState({
            status : true
        })

        // return true

        let mode = this.state.mode
        let type = this.state.window
        let umid = this.state.umid
        let apiKey = this.state.apiKey
        let apiSecret = this.state.secretKey
        let amount = this.state.amount

        var hostedURL
        var merchantTxnDtm = moment().format('YYYYMMDD hh:mm:ss.SSS')
        var merchantTxnRef = moment().format('YYYYMMDD hh:mm:ss')
    
        var script1 = document.createElement("script")
        var script2 = document.createElement("script")
    
        if (mode === 'uat') {
            script1.src = 'https://uat2.enets.sg/GW2/pluginpages/env.jsp'
            document.getElementsByTagName('head')[0].appendChild(script1)
    
            script1.onload = function() {
                script2.src = 'https://uat2.enets.sg/GW2/js/apps.js'
                script2.type = 'text/javascript'
                document.getElementsByTagName('head')[0].appendChild(script2)
            };

            hostedURL = 'https://uat2.enets.sg/GW2/TxnReqListenerToHost'
    
        } else if (mode === "prod") {
            script1.src = 'https://www2.enets.sg/GW2/pluginpages/env.jsp'
            document.getElementsByTagName('head')[0].appendChild(script1)
    
            script1.onload = function() {
                script2.src = 'https://www2.enets.sg/GW2/js/apps.js'
                script2.type = 'text/javascript'
                document.getElementsByTagName('head')[0].appendChild(script2)
            };
            hostedURL = 'https://www2.enets.sg/GW2/TxnReqListenerToHost'
        }

        var data = JSON.stringify({"ss":"1","msg":{"netsMid":umid,"tid":"","submissionMode":"B","txnAmount":amount,"merchantTxnRef":merchantTxnRef,"merchantTxnDtm":merchantTxnDtm,"paymentType":"SALE","currencyCode":"SGD","paymentMode":"","merchantTimeZone":"+8:00","b2sTxnEndURL":"https://httpbin.org/post","b2sTxnEndURLParam":"","s2sTxnEndURL":"http://s2s-parser.requestcatcher.com/test","s2sTxnEndURLParam":"","clientType":"W","supMsg":"","netsMidIndicator":"U","ipAddress":"127.0.0.1","language":"en"}})
        
        var sign = btoa(sha256(data + apiSecret).match(/\w{2}/g).map(function (a) {
            return String.fromCharCode(parseInt(a, 16))
        }).join(''))

        this.setState({
            payloadReqContent : data,
            payloadReqSign : sign
        })

        if (type === "current") {
            script2.onload = script2.onreadystatechange = async function(a) {
                if (!this.readyState || this.readyState === "loaded" || this.readyState === "complete"){

                    window['sendPayLoad'](data, sign, apiKey)
                    // Handle memory leak in IE
                    script2.onreadystatechange = null;
                }
            }
        } else if (type === "hosted") {
            var newForm = jQuery('<form>', {
                'action': hostedURL,
                'method': 'POST',
                'name': "eNETSRedirectForm",
                'id': 'eNETSRedirectForm'
              }).append(jQuery('<input>', {
                'type': 'hidden',
                'id': 'payload',
                'name': 'payload',
                'value': data
              })).append(jQuery('<input>', {
                'type': 'hidden',
                'id': 'apiKey',
                'name': 'apiKey',
                'value': apiKey
              })).append(jQuery('<input>', {
                'type': 'hidden',
                'id': 'hmac',
                'name': 'hmac',
                'value': sign
              }));

            (document.body).append(newForm);
    
            (newForm).submit();
        }
    }

    render() {
        if(isLoggedIn()){
            return (
                <Layout>
                    <Banner title={`eNETS Credit Demo`} />
                    <Container
                        style={{
                        padding: '2rem 2rem 1rem',
                        minHeight: '75vh'
                        }}
                    >
                        
                        <Form>

                            <div className="data-input">
                                <table className="table table-condensed" align="center">
                                    <thead>
                                    <tr>
                                        <th>Fields</th>
                                        <th>Input</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <Label>Amount (Cents) </Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        size="40"
                                                        name="amount"
                                                        id="amount"
                                                        defaultValue="1000"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>UMID</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="umid"
                                                        defaultValue="UMID_877772003"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>API Key</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="apiKey"
                                                        defaultValue="154eb31c-0f72-45bb-9249-84a1036fd1ca"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Secret Key</Label>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                        
                                                    <Input 
                                                        type="text"
                                                        id="secretKey"
                                                        defaultValue="38a4b473-0295-439d-92e1-ad26a8c60279"
                                                        onChange={this.handleChange}
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Open In </Label>
                                            </td>
                                            <td>
                                                <ButtonGroup>
                                                    <Button color={this.state.window === 'current' ? 'primary' : 'secondary'} onClick={() => this.onRadioBtnClick('current', 'window')} active={this.state.window === 'current'}>Current Page</Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <Label>Mode</Label>
                                            </td>
                                            <td>
                                                <ButtonGroup>
                                                    <Button 
                                                        color={this.state.mode === 'uat' ? 'primary' : 'secondary'} 
                                                        onClick={event => {
                                                            event.preventDefault()
                                                            this.onRadioBtnClick('uat', 'mode')
                                                        }}
                                                        active={this.state.mode === 'uat'}
                                                    >
                                                        UAT
                                                    </Button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            
                            <Container
                                className="text-right"
                                style={{
                                    margin : `0.875rem 0`
                                }}
                            >
                                <Button
                                    color="link"
                                    onClick={event => {
                                        event.preventDefault()
                                        navigate('/docs/reference')
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button
                                    color="primary"
                                    onClick={ e => {
                                        e.preventDefault()
                                        this.submitHandler()
                                    }}
                                >
                                    Send Payload
                                </Button>
                            </Container>
                        </Form>
                        <div className="payment-section" id="ajaxResponse">
                        </div>
                        <div id="soapi-time" className="container">
                        <hr />
                        {this.state.payloadResContent ? (
                            <div>
                                {/* Response : Payload */}
                                <h3>[RESPONSE] Payload</h3>
                                <pre style={{
                                    whiteSpace: `pre-wrap`,
                                    textAlign: `justify`,
                                    wordBreak: `break-all`,
                                    padding : `5px 10px`
                                }}>
                                    {this.state.payloadResContent}
                                </pre>
                            </div>
                        ) : null}
                        {this.state.payloadReqContent ? (
                            <div>
                                {/* Request : Payload */}
                                <h3>[REQUEST] Payload</h3>
                                <pre style={{
                                    whiteSpace: `pre-wrap`,
                                    textAlign: `justify`,
                                    wordBreak: `break-all`,
                                    padding : `5px 10px`
                                }}>
                                    {this.state.payloadReqContent}
                                </pre>

                                {/* Request : Payload Hash */}
                                <h3>[REQUEST] Payload Hash</h3>
                                <pre style={{
                                    whiteSpace: `pre-wrap`,
                                    textAlign: `left`,
                                    wordBreak: `break-all`,
                                    padding : `5px 10px`
                                }}>
                                    {this.state.payloadReqSign}
                                </pre>
                            </div>
                        ) : (
                            null
                        )}
                        
                        <p style={{textAlign: `center`}}>UAT simulater (Credit & Debit) is available daily from 0900H to 1600H (GMT+8)<br /><a href={`/support/schedule`} >Maintenance Schedule</a></p>
                    </div>
                        {/* <iframe title="nets" src="https://loichiilek.github.io/soapi.html" width="100%" frameBorder="none"/> */}
                </Container>
            </Layout>
            )
        } else {
            return ''
        }
    }
}
